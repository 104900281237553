<template>
  <div class="menu-modal">
    <div @click="closeModal">
      <NuxtLink to="/avantages"> Les avantages We Are PlayStation </NuxtLink>
      <NuxtLink to="/actualites-communautes">
        L'actualité des communautés
      </NuxtLink>
      <NuxtLink to="/avis-joueurs"> Les avis des joueurs </NuxtLink>
      <NuxtLink to="/challenges"> Les challenges </NuxtLink>
      <NuxtLink to="/concours"> Les concours </NuxtLink>
      <NuxtLink to="/astuces">Les astuces</NuxtLink>
      <hr />
    </div>
    <template v-if="communitiesMenu">
      <details
        v-if="communitiesMenu.universes.length"
        class="accordion"
      >
        <summary class="accordion__header">Les univers de jeux</summary>
        <div class="accordion__body">
          <NuxtLink
            v-for="universe in communitiesMenu.universes"
            :key="`universe-${universe.slug}`"
            :to="`/communautes/genre/${universe.slug}`"
            @click="closeModal"
          >
            {{ universe.title }}
          </NuxtLink>
        </div>
      </details>
      <details
        v-if="communitiesMenu.types.length"
        class="accordion"
      >
        <summary class="accordion__header">Les types de communautés</summary>
        <div class="accordion__body">
          <NuxtLink
            v-for="typeItem in communitiesMenu.types"
            :key="`type-${typeItem.slug}`"
            :to="`/communautes/type/${typeItem.slug}`"
            @click="closeModal"
          >
            Les communautés {{ typeItem.title }}
          </NuxtLink>
        </div>
      </details>
    </template>
    <div @click.capture="closeModal">
      <NuxtLink to="/communautes"> Toutes les communautés </NuxtLink>
      <hr />
      <NuxtLink to="/wapers">Les Wapers</NuxtLink>
      <NuxtLink to="/le-feed-des-players"> Le feed des players </NuxtLink>
      <hr />
      <NuxtLink to="/actualites-playstation">
        L'actualité PlayStation
      </NuxtLink>
      <NuxtLink to="/avis-medias"> Les avis des médias </NuxtLink>
      <NuxtLink to="/offres"> Les offres du moment PlayStation Store </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'

const vfm = useVfm()
const closeModal = () => vfm.close('menu')

const crossContentStore = useCrossContentStore()
const { communitiesMenu } = storeToRefs(crossContentStore)
</script>

<style lang="scss" scoped>
.menu-modal {
  @media (max-width: 699px) {
    padding-bottom: 10rem;
  }
}

hr {
  margin: 1.5rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

a {
  display: block;
  color: $dark;
  line-height: 4rem;
  text-decoration: none;
}

.accordion {
  $root: &;

  &[open] {
    #{$root}__header::after {
      transform: rotate(-180deg);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    line-height: 4rem;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 10px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6'%3e%3cpath d='M8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4A1 1 0 0 1 1.707.293l3.294 3.293Z' fill='%230072ce'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 10px;
      transform: rotate(0);
      transition: transform 0.35s cubic-bezier(0.62, 0.28, 0.23, 0.99);
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__body {
    padding: 0 0 0 20px;

    a {
      padding: 8px 0;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
    }
  }
}
</style>
