<template>
  <div class="sidebar">
    <div
      v-if="!authenticated"
      class="sidebar--anonymous"
    >
      <div class="container">
        <p class="title">
          Prêt à rejoindre
          <br />
          la communauté&nbsp;?
        </p>
        <div
          ref="intro"
          class="intro"
          v-html="menuInfos?.intro ?? ''"
        />
        <div
          ref="buttons"
          class="o-row u-ai-c u-jc-sb u-jc-fe@md buttons-container"
        >
          <div class="o-col u-auto">
            <ButtonSimple
              route="/inscription"
              label="S'inscrire"
              color="white"
            />
          </div>
          <div class="o-col u-auto link-button">
            <p class="button-login">Déjà inscrit(e) ?</p>
          </div>
        </div>
      </div>
      <template v-if="menuInfos?.image_desktop?.url">
        <div>
          <div class="img-container">
            <img
              :src="
                !isSmallScreen
                  ? menuInfos.image_desktop.url
                  : menuInfos.image_mobile?.url ?? menuInfos.image_desktop.url
              "
              :alt="menuInfos.intro"
            />
          </div>
        </div>
      </template>
    </div>
    <div
      v-else
      class="sidebar--logged"
    >
      <div class="container">
        <AccountHeader menu-layout />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const crossContentStore = useCrossContentStore()

const { crossContent } = storeToRefs(crossContentStore)

const menuInfos = computed(() => crossContent.value?.menuInfos)
const height = ref('0')
const height2 = ref('0')
const buttons = ref<HTMLInputElement | null>(null)
const intro = ref<HTMLInputElement | null>(null)

const { isSmallScreen } = useCustomMediaQuery()
const { authenticated } = useTempAuth()

const onResize = () => {
  if (buttons.value) {
    const buttonsHeight = buttons.value.getBoundingClientRect().height
    height.value = `${buttonsHeight}px`
  }

  if (intro.value) {
    const introHeight = intro.value.getBoundingClientRect().top - 14
    height2.value = `${introHeight}px`
  }
}
onMounted(async () => {
  await nextTick()

  useEventListener('resize', onResize)

  onResize()
})
</script>

<style lang="scss">
.sidebar {
  .intro {
    width: 357px;
    margin-bottom: 3rem;

    p {
      font-weight: 300;
      line-height: 1.7;
    }

    @media (min-width: 992px) {
      p {
        font-size: 2rem;
        line-height: 1.6;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.sidebar {
  flex: 1 1 auto;
  color: #fff;

  .hr {
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, $white, transparent);
    opacity: 0.3;
  }

  &--anonymous {
    position: relative;
    display: flex;
    height: 100%;
    background-color: $blue;

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 3rem 2rem 4rem;

      @media (min-width: 992px) {
        padding: 5rem 3rem 4rem;
      }

      @media (min-width: 1200px) {
        padding: 7rem 9rem 9rem 12rem;
      }
    }

    .title {
      margin-bottom: 1.4rem;
      font-family: 'SST Condensed';
      font-size: 2.2rem;
      line-height: 1.45;
      letter-spacing: 0.2em;
      text-transform: uppercase;

      @media (min-width: 992px) {
        margin-bottom: 2rem;
        font-size: 3.4rem;
      }
    }

    .button-login {
      margin-top: 2.5rem;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;

      @media (min-width: 1280px) {
        margin-top: 0;
      }
    }

    .img-container {
      position: absolute;
      top: calc(v-bind(height2) - 30px);
      right: 0;
      bottom: 0;
      width: 47%;
      max-width: calc(100% - 170px - 25px);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom right;
      }

      @media (min-width: 500px) {
        top: 30px;
        width: 50%;
      }

      @media (min-width: 992px) {
        top: calc(v-bind(height) - 30px);
        bottom: 0;
      }

      @media (min-width: 1280px) {
        top: 7rem;
        max-width: calc(100% - 357px - 120px);
      }
    }

    .intro {
      width: 170px;
      margin-bottom: 3rem;
      font-weight: 300 !important;
      line-height: 1.7 !important;

      @media (min-width: 500px) {
        width: 230px;
      }

      @media (min-width: 992px) {
        width: 357px;
        font-size: 2rem !important;
        line-height: 1.6 !important;
      }
    }

    .buttons-container {
      flex-direction: column;

      @media (min-width: 1281px) {
        flex-direction: row;
      }

      .link-button {
        font-size: 15px;
      }
    }

    .button-point {
      margin-top: 2.5rem;

      @media (min-width: 992px) {
        margin-top: 0;
        margin-bottom: 5rem;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: auto;
    }
  }

  &--logged {
    display: flex;
    height: 100%;
    background-image: linear-gradient(to bottom left, #071738 10%, #162c5b 90%);

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>
