import MenuModal from '@/components/modal/MenuModal.vue'
import MenuSidebar from '@/components/modal/sidebar/MenuSidebar.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

const getMenuModalConfig = () => ({
  attrs: {
    modalId: 'menu',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: MenuModal,
    }),
    sidebar: MenuSidebar,
  },
})

export const menuModal = useCustomModal(getMenuModalConfig())
